import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/main/PrivateRoute';
import { ApiProvider } from './contexts/ApiContext';
import { ModalProvider } from './contexts/ModalContext'; // Adjust path
import { HelmetProvider } from 'react-helmet-async';

import Login from './components/auth/Login';
import EmailLogin from './components/auth/EmailLogin';
import OTP from './components/auth/OTP';
import SetPassword from './components/auth/SetPassword';
import Logout from './components/auth/Logout';
import MainApp from './components/main/MainApp';

import CheerBuddyDashboard from './components/dashboards/Leads/Leads';
import TemplateDashboard from './components/dashboards/Templates/Templates';
import AutomationDashboard from './components/dashboards/Automations/Automations';
import WebFormsDashboard from './components/dashboards/Webforms/Webforms';
import CheerBuddy from './components/leads/Leads';
import CreateLead from './components/leads/create';
import Bulkupload from './components/leads/bulkUpload';
import Templates from './components/templates/editTemplate';
import CreateTemplate from './components/templates/createTemplate';
import CreateAutomation from './components/automation/createAutomation';
import WebForms from './components/Webforms/Webforms';
import Report from './components/dashboards/Report/Report';
import AutomationReport from './components/Report/Automation';
import TransactionalReport from './components/Report/Transactional';
import Profile from './components/profile/Profile';
import ChangePassword from './components/profile/ChangePassword';


const App = () => {
  const [status, setStatus] = useState(null);
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <ApiProvider>
          <ModalProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/login/email" element={<EmailLogin
                setStatus={setStatus}
              />} />
              <Route path="/login/email/otp" element={<OTP
                status={status}
              />} />
              <Route path="/setpassword" element={<SetPassword />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/dashboard" element={<PrivateRoute><MainApp /></PrivateRoute>} >
                <Route path='/dashboard' element={<PrivateRoute><CheerBuddyDashboard /></PrivateRoute>}></Route>
                <Route path='/dashboard/Lead/:LeadId' element={<PrivateRoute><CheerBuddy /></PrivateRoute>}></Route>
                <Route path='/dashboard/Lead/create' element={<PrivateRoute><CreateLead /></PrivateRoute>}></Route>
                <Route path='/dashboard/Lead/bulkupload' element={<PrivateRoute><Bulkupload /></PrivateRoute>}></Route>
                <Route path='/dashboard/Templates/' element={<PrivateRoute><TemplateDashboard /></PrivateRoute>}></Route>
                <Route path='/dashboard/Templates/create' element={<PrivateRoute><CreateTemplate /></PrivateRoute>}></Route>
                <Route path='/dashboard/Templates/view/:TemplateId' element={<PrivateRoute><Templates /></PrivateRoute>}></Route>
                <Route path='/dashboard/webforms/' element={<PrivateRoute><WebFormsDashboard /></PrivateRoute>}></Route>
                <Route path='/dashboard/webforms/:WebFormId' element={<PrivateRoute><WebForms /></PrivateRoute>}></Route>
                <Route path='/dashboard/webforms/submissions/:EntryId' element={<PrivateRoute><WebForms /></PrivateRoute>}></Route>
                <Route path='/dashboard/Automations/' element={<PrivateRoute><AutomationDashboard /></PrivateRoute>}></Route>
                <Route path='/dashboard/Automations/create' element={<PrivateRoute><CreateAutomation /></PrivateRoute>}></Route>
                <Route path='/dashboard/Report/' element={<PrivateRoute><Report /></PrivateRoute>}></Route>
                <Route path='/dashboard/Reports/transactional' element={<PrivateRoute><TransactionalReport /></PrivateRoute>}></Route>
                <Route path='/dashboard/Reports/:ReportId' element={<PrivateRoute><AutomationReport /></PrivateRoute>}></Route>
                <Route path='/dashboard/Templates/view/:TemplateId' element={<PrivateRoute><Templates /></PrivateRoute>}></Route>
                <Route path='/dashboard/profile' element={<PrivateRoute><Profile /></PrivateRoute>}></Route>
                <Route path='/dashboard/profile/changepassword' element={<PrivateRoute><ChangePassword /></PrivateRoute>}></Route>
              </Route>
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </ModalProvider>
        </ApiProvider>
      </Router>
    </HelmetProvider>
  );
};

export default App;
