import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken, removeToken } from '../../utils/secureStorage';
import api from '../../api/axiosInstance';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      const refreshToken = getToken('refreshToken');
      let res = await api.post(`/auth/logout`,
        {
          refreshToken: refreshToken,
        }
      );
      if (!res.data.error) {
        removeToken('accessToken');
        removeToken('refreshToken');
        navigate('/login');
      } else {
        window.location.reload();
      }
    };

    performLogout();
  }, [navigate]);

  return null;
};

export default Logout;
