import React, { createContext, useState, useContext } from 'react';
import Modal from '../components/modal/alert';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState({ status: '', message: '', isOpen: false });

  const openModal = (status, message) => {
    setModalData({ status, message, isOpen: true });
  };

  const closeModal = () => {
    setModalData({ ...modalData, isOpen: false });
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalData.isOpen && (
        <Modal
          status={modalData.status}
          message={modalData.message}
          onClose={closeModal}
        />
      )}
    </ModalContext.Provider>
  );
};
