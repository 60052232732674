import React from 'react';

const Modal = ({ status, message, onClose }) => {
    const isSuccess = status === 'success';

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-black bg-opacity-50 absolute inset-0" onClick={onClose}></div>
            <div className="bg-white p-6 rounded-2xl shadow-lg z-10 max-w-sm w-full">
                <div className="text-center">
                    {isSuccess ? (
                        <div className="text-green-500">
                            <div className='rounded-full w-fit bg-gray-100 mx-auto'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-16 w-16 mx-auto mb-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <h3 className="text-lg font-semibold">{message}</h3>
                        </div>
                    ) : (
                        <div className="text-red-500">
                            <div className='rounded-full w-fit bg-gray-100 mx-auto'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-16 w-16 mx-auto mb-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <h3 className="text-lg font-semibold">{message}</h3>
                        </div>
                    )}
                    <button className="mt-3 relative flex h-11 text-white w-full items-center justify-center px-5 before:absolute before:inset-0 before:rounded-full before:bg-[#9333ea] before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95"
                        onClick={onClose} >
                        <span class="relative w-max text-white">
                            Close
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
