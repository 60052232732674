import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
  return (
    <>
      <aside className="hidden lg:block fixed top-0 left-0 z-[35] w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 lg:translate-x-0 dark:bg-gray-900 dark:border-gray-700" aria-label="Sidebar">
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-900">
          <ul className="space-y-2 font-medium">
            <li>
              <Link to={'/dashboard'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100" xmlSpace="preserve"
                ><g><g><circle cx={50} cy={29} r={9} /></g><g><path d="M78,44H50H22c-2,0-2.8,2.5-1.1,3.6L35.5,57c0.7,0.5,1.1,1.4,0.8,2.2l-5.5,18.3c-0.6,2,2,3.4,3.5,1.9l14.2-15 c0.8-0.9,2.2-0.9,3,0l14.2,15c1.4,1.5,4,0.1,3.5-1.9l-5.5-18.3c-0.2-0.8,0.1-1.7,0.8-2.2l14.6-9.4C80.8,46.5,80,44,78,44z" /></g></g>
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Leads</span>
              </Link>
            </li>
            <li>
              <Link to={'/dashboard/Templates'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" xmlSpace="preserve">
                  <path
                    d="M9.5547 11.1679C9.09517 10.8616 8.4743 10.9858 8.16795 11.4453C7.8616 11.9048 7.98577 12.5257 8.4453 12.8321L12.3906 15.4622C13.0624 15.9101 13.9376 15.9101 14.6094 15.4622L18.5547 12.8321C19.0142 12.5257 19.1384 11.9048 18.8321 11.4453C18.5257 10.9858 17.9048 10.8616 17.4453 11.1679L13.5 13.7981L9.5547 11.1679Z"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 8C2 5.79086 3.79086 4 6 4H15C16.9002 4 18.4909 5.32493 18.8987 7.10135C20.6751 7.50915 22 9.09984 22 11V17C22 19.2091 20.2091 21 18 21H9C6.79086 21 5 19.2091 5 17V16.9859C3.31747 16.8307 2 15.4153 2 13.6923V8ZM15 6C15.7403 6 16.3866 6.4022 16.7324 7H9C6.79086 7 5 8.79086 5 11V14.9636C4.42625 14.8252 4 14.3085 4 13.6923V8C4 6.89543 4.89543 6 6 6H15ZM9 9C7.89543 9 7 9.89543 7 11V17C7 18.1046 7.89543 19 9 19H18C19.1046 19 20 18.1046 20 17V11C20 9.89543 19.1046 9 18 9H9Z"
                  />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Templates</span>
              </Link>
            </li>
            <li>
              <Link to={'#'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001"><path d="M463.996 126.864 340.192 3.061A10.452 10.452 0 0 0 332.803 0H95.726C67.724 0 44.944 22.782 44.944 50.784v410.434c0 28.001 22.781 50.783 50.783 50.783h320.547c28.002 0 50.783-22.781 50.783-50.783V134.253a10.458 10.458 0 0 0-3.061-7.389zM343.255 35.679l88.127 88.126H373.14c-7.984 0-15.49-3.109-21.134-8.753-5.645-5.643-8.752-13.148-8.751-21.131V35.679zm102.903 425.538c0 16.479-13.406 29.885-29.884 29.885H95.726c-16.479 0-29.885-13.406-29.885-29.885V50.784c.001-16.479 13.407-29.886 29.885-29.886h226.631v73.021c-.002 13.565 5.28 26.318 14.871 35.909 9.592 9.592 22.345 14.874 35.911 14.874h73.018v316.515z" /><path d="M147.789 303.745h-4.678c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h4.678c5.77 0 10.449-4.678 10.449-10.449s-4.679-10.449-10.449-10.449zM368.889 303.745H181.593c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h187.296c5.77 0 10.449-4.678 10.449-10.449s-4.679-10.449-10.449-10.449zM147.789 256h-4.678c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h4.678c5.77 0 10.449-4.678 10.449-10.449S153.559 256 147.789 256zM368.889 256H181.593c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h187.296c5.77 0 10.449-4.678 10.449-10.449S374.659 256 368.889 256zM147.789 208.255h-4.678c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h4.678c5.77 0 10.449-4.678 10.449-10.449s-4.679-10.449-10.449-10.449zM368.889 208.255H181.593c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h187.296c5.77 0 10.449-4.678 10.449-10.449s-4.679-10.449-10.449-10.449z" /></svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Web Forms
                  <span class="ms-2 bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2 py-0.5 rounded-full dark:bg-purple-900 dark:text-purple-300">Comming Soon</span>
                </span>
              </Link>
            </li>
            <li>
              <Link to={'/dashboard/Automations'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16.615 16.615" xmlSpace="preserve"><g><g><path d="M16.614,5.208c0-0.276-0.225-0.501-0.502-0.501H7.53l1.208,0.813 c0.078,0.053,0.142,0.119,0.199,0.19h6.455L10.408,9.32L7.971,7.566l-0.965,0.65l3.111,2.218c0.17,0.123,0.412,0.121,0.582,0 l4.912-3.502v5.09H5.205V9.428L5.003,9.565C4.845,9.669,4.669,9.721,4.488,9.721C4.39,9.721,4.295,9.7,4.202,9.67v2.854 c0,0.277,0.225,0.502,0.502,0.502h11.409c0.277,0,0.502-0.225,0.502-0.502V5.958c0-0.026-0.004-0.05-0.008-0.072 c0.006-0.027,0.008-0.054,0.008-0.08V5.208H16.614z" /><path
                  d="M4.308,8.799c0,0.066,0.037,0.127,0.096,0.158c0.06,0.031,0.13,0.027,0.185-0.01l3.735-2.514 C8.373,6.4,8.403,6.344,8.403,6.283c0-0.06-0.03-0.115-0.079-0.149L4.59,3.621C4.535,3.585,4.464,3.58,4.405,3.611 c-0.059,0.032-0.096,0.093-0.096,0.16v1.454C3.721,5.221,3.235,5.195,2.83,5.146C0.888,4.916,0.368,3.75,0.347,3.7 C0.318,3.632,0.252,3.59,0.181,3.59c-0.012,0-0.024,0-0.035,0.003C0.06,3.611,0,3.685,0,3.77c0,2.944,3.495,3.571,4.307,3.677 L4.308,8.799L4.308,8.799z" /></g></g>
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Automations</span>
              </Link>
            </li>
            <li>
              <Link to={'/dashboard/Report'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                  <g fill-rule="evenodd">
                    <rect x="15" rx="1" width="3" height="18" />
                    <rect x="10" y="5" width="3" height="13" rx="1" />
                    <rect x="5" y="9" width="3" height="9" rx="1" />
                    <rect y="13" width="3" height="5" rx="1.001" />
                  </g>
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Reports</span>
              </Link>
            </li>
            <li>
              <Link to={'/logout'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3" />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
      <aside id="sidebar" className="block lg:hidden fixed top-0 left-0 z-[35] w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 lg:translate-x-0 dark:bg-gray-900 dark:border-gray-700" aria-label="Sidebar">
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-900">
          <ul className="space-y-2 font-medium">
            <li>
              <Link to={'/dashboard'} data-drawer-target="sidebar" data-drawer-toggle="sidebar" aria-controls="sidebar" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100" xmlSpace="preserve"
                ><g><g><circle cx={50} cy={29} r={9} /></g><g><path d="M78,44H50H22c-2,0-2.8,2.5-1.1,3.6L35.5,57c0.7,0.5,1.1,1.4,0.8,2.2l-5.5,18.3c-0.6,2,2,3.4,3.5,1.9l14.2-15 c0.8-0.9,2.2-0.9,3,0l14.2,15c1.4,1.5,4,0.1,3.5-1.9l-5.5-18.3c-0.2-0.8,0.1-1.7,0.8-2.2l14.6-9.4C80.8,46.5,80,44,78,44z" /></g></g>
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Leads</span>
              </Link>
            </li>
            <li>
              <Link to={'/dashboard/Templates'} data-drawer-target="sidebar" data-drawer-toggle="sidebar" aria-controls="sidebar" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" xmlSpace="preserve">
                  <path
                    d="M9.5547 11.1679C9.09517 10.8616 8.4743 10.9858 8.16795 11.4453C7.8616 11.9048 7.98577 12.5257 8.4453 12.8321L12.3906 15.4622C13.0624 15.9101 13.9376 15.9101 14.6094 15.4622L18.5547 12.8321C19.0142 12.5257 19.1384 11.9048 18.8321 11.4453C18.5257 10.9858 17.9048 10.8616 17.4453 11.1679L13.5 13.7981L9.5547 11.1679Z"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 8C2 5.79086 3.79086 4 6 4H15C16.9002 4 18.4909 5.32493 18.8987 7.10135C20.6751 7.50915 22 9.09984 22 11V17C22 19.2091 20.2091 21 18 21H9C6.79086 21 5 19.2091 5 17V16.9859C3.31747 16.8307 2 15.4153 2 13.6923V8ZM15 6C15.7403 6 16.3866 6.4022 16.7324 7H9C6.79086 7 5 8.79086 5 11V14.9636C4.42625 14.8252 4 14.3085 4 13.6923V8C4 6.89543 4.89543 6 6 6H15ZM9 9C7.89543 9 7 9.89543 7 11V17C7 18.1046 7.89543 19 9 19H18C19.1046 19 20 18.1046 20 17V11C20 9.89543 19.1046 9 18 9H9Z"
                  />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Templates</span>
              </Link>
            </li>
            <li>
              <Link to={'#'} data-drawer-target="sidebar" data-drawer-toggle="sidebar" aria-controls="sidebar" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001"><path d="M463.996 126.864 340.192 3.061A10.452 10.452 0 0 0 332.803 0H95.726C67.724 0 44.944 22.782 44.944 50.784v410.434c0 28.001 22.781 50.783 50.783 50.783h320.547c28.002 0 50.783-22.781 50.783-50.783V134.253a10.458 10.458 0 0 0-3.061-7.389zM343.255 35.679l88.127 88.126H373.14c-7.984 0-15.49-3.109-21.134-8.753-5.645-5.643-8.752-13.148-8.751-21.131V35.679zm102.903 425.538c0 16.479-13.406 29.885-29.884 29.885H95.726c-16.479 0-29.885-13.406-29.885-29.885V50.784c.001-16.479 13.407-29.886 29.885-29.886h226.631v73.021c-.002 13.565 5.28 26.318 14.871 35.909 9.592 9.592 22.345 14.874 35.911 14.874h73.018v316.515z" /><path d="M147.789 303.745h-4.678c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h4.678c5.77 0 10.449-4.678 10.449-10.449s-4.679-10.449-10.449-10.449zM368.889 303.745H181.593c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h187.296c5.77 0 10.449-4.678 10.449-10.449s-4.679-10.449-10.449-10.449zM147.789 256h-4.678c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h4.678c5.77 0 10.449-4.678 10.449-10.449S153.559 256 147.789 256zM368.889 256H181.593c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h187.296c5.77 0 10.449-4.678 10.449-10.449S374.659 256 368.889 256zM147.789 208.255h-4.678c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h4.678c5.77 0 10.449-4.678 10.449-10.449s-4.679-10.449-10.449-10.449zM368.889 208.255H181.593c-5.77 0-10.449 4.678-10.449 10.449s4.679 10.449 10.449 10.449h187.296c5.77 0 10.449-4.678 10.449-10.449s-4.679-10.449-10.449-10.449z" /></svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Web Forms
                  <span class="ms-2 bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2 py-0.5 rounded-full dark:bg-purple-900 dark:text-purple-300">Comming Soon</span>
                </span>
              </Link>
            </li>
            <li>
              <Link to={'/dashboard/Automations'} data-drawer-target="sidebar" data-drawer-toggle="sidebar" aria-controls="sidebar" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16.615 16.615" xmlSpace="preserve"><g><g><path d="M16.614,5.208c0-0.276-0.225-0.501-0.502-0.501H7.53l1.208,0.813 c0.078,0.053,0.142,0.119,0.199,0.19h6.455L10.408,9.32L7.971,7.566l-0.965,0.65l3.111,2.218c0.17,0.123,0.412,0.121,0.582,0 l4.912-3.502v5.09H5.205V9.428L5.003,9.565C4.845,9.669,4.669,9.721,4.488,9.721C4.39,9.721,4.295,9.7,4.202,9.67v2.854 c0,0.277,0.225,0.502,0.502,0.502h11.409c0.277,0,0.502-0.225,0.502-0.502V5.958c0-0.026-0.004-0.05-0.008-0.072 c0.006-0.027,0.008-0.054,0.008-0.08V5.208H16.614z" /><path
                  d="M4.308,8.799c0,0.066,0.037,0.127,0.096,0.158c0.06,0.031,0.13,0.027,0.185-0.01l3.735-2.514 C8.373,6.4,8.403,6.344,8.403,6.283c0-0.06-0.03-0.115-0.079-0.149L4.59,3.621C4.535,3.585,4.464,3.58,4.405,3.611 c-0.059,0.032-0.096,0.093-0.096,0.16v1.454C3.721,5.221,3.235,5.195,2.83,5.146C0.888,4.916,0.368,3.75,0.347,3.7 C0.318,3.632,0.252,3.59,0.181,3.59c-0.012,0-0.024,0-0.035,0.003C0.06,3.611,0,3.685,0,3.77c0,2.944,3.495,3.571,4.307,3.677 L4.308,8.799L4.308,8.799z" /></g></g>
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Automations</span>
              </Link>
            </li>
            <li>
              <Link to={'/dashboard/Report'} data-drawer-target="sidebar" data-drawer-toggle="sidebar" aria-controls="sidebar" className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <svg className="flex-shrink-0 w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                  <g fill-rule="evenodd">
                    <rect x="15" rx="1" width="3" height="18" />
                    <rect x="10" y="5" width="3" height="13" rx="1" />
                    <rect x="5" y="9" width="3" height="9" rx="1" />
                    <rect y="13" width="3" height="5" rx="1.001" />
                  </g>
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Reports</span>
              </Link>
            </li>
            <li>
              <Link to={'/logout'} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3" />
                </svg>
                <span className="flex-1 ms-3 whitespace-nowrap">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>

    </>
  );
};

export default Sidebar;
