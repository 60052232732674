import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './NavBar';
import Sidebar from './Sidebar';
import { initFlowbite } from 'flowbite';

const MainApp = () => {
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [breadcrumbLink, setBreadcrumbLink] = useState([]);

  useEffect(() => {
    initFlowbite()
  }, [])

  return (
    <>
      <Navbar breadcrumb={breadcrumb} breadcrumbLink={breadcrumbLink} />
      <Sidebar />
      <div className="flex pt-16 overflow-hidden bg-gray-50 dark:bg-gray-900">
        <div className="fixed inset-0 z-10 hidden bg-gray-900/50 dark:bg-gray-900/90" id="sidebarBackdrop"></div>
        <div id="main-content" className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-64 dark:bg-gray-900">
          <main>
            <Outlet context={[setBreadcrumb, setBreadcrumbLink]}></Outlet>
          </main>
        </div>
      </div>
      <script src="https://unpkg.com/flowbite@latest/dist/flowbite.js"></script>
    </>
  );
};

export default MainApp;
