import React, { useState, useEffect } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import api from '../../api/axiosInstance';
import { useModal } from '../../contexts/ModalContext';

const Settings = () => {
    const [setBreadcrumb, setBreadcrumbLink] = useOutletContext();
    const [moderatorsName, setModeratorsName] = useState('');
    const [totalCredits, setTotalCredits] = useState(0);
    const [consumedCredits, setConsumedCredits] = useState(0);
    const [emailCredits, setEmailCredits] = useState(0);
    const [moderatorsEmail, setModeratorsEmail] = useState('');

    const { openModal } = useModal();

    useEffect(() => {
        setBreadcrumb(['Profile']);
        setBreadcrumbLink(['/dashboard/settings']);
    }, [setBreadcrumb, setBreadcrumbLink]);

    useEffect(() => {
        const fetchShortProfile = async () => {
            try {
                const response = await api.get(`/clients/profile`);
                const data = response.data.profile;
                setEmailCredits(data.emailCredits);
                setTotalCredits(data.creditsPurchased);
                setConsumedCredits(data.creditsPurchased-data.emailCredits);
                setModeratorsName(data.name);
                setModeratorsEmail(data.username);
            } catch (error) {
                if (error.message === 'Network Error') {
                    openModal('failure', "No Internet Connection.");
                } else {
                    openModal('failure', error.message);
                }
            }
        }

        fetchShortProfile();
    }, []);
    return (
        <>
            <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900">
                <div className="row-span-2 p-4 h-fit mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                    <div className="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
                        <img className="mb-4 rounded-lg w-28 h-28 sm:mb-0 xl:mb-4 2xl:mb-0" src={'/images/profile.jpg'} alt="Profile" />
                        <div>
                            <h3 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">{moderatorsName} </h3>

                            <div className="flex items-center space-x-4">
                                <Link to={'/dashboard/profile/changepassword'} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                                    Change Password
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                        <h3 className="mb-4 text-xl font-semibold dark:text-white">Profile</h3>
                        <form action="#">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                    <input value={moderatorsName} type="text" name="name" id="name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="Aditya Dubey" disabled={true} />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                    <input value={moderatorsEmail} type="email" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500" placeholder="example@company.com" disabled={true} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                        <h3 className="mb-4 text-xl font-semibold dark:text-white">Email Credits</h3>
                        <form action="#">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-2 p-2 rounded bg-slate-100">
                                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Total Email Credits</label>
                                    <span className='font-medium text-lg'>{totalCredits}</span>
                                </div>
                                <div className="col-span-6 sm:col-span-2 p-2 rounded bg-slate-100">
                                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Credits Consumed</label>
                                    <span className='font-medium text-lg'>{consumedCredits}</span>
                                </div>
                                <div className="col-span-6 sm:col-span-2 p-2 rounded bg-slate-100">
                                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email Credits Left</label>
                                    <span className='font-medium text-lg'>{emailCredits}</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Settings;
