import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from 'axios';
import sampleFile from '../../assets/files/sample_leads.xlsx';
import { useModal } from '../../contexts/ModalContext';
import { getToken } from '../../utils/secureStorage';

const Post = () => {
    const navigate = useNavigate();
    const [setBreadcrumb, setBreadcrumbLink] = useOutletContext();
    const [proceeding, setProceeding] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const { openModal } = useModal();

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    useEffect(() => {
        setBreadcrumb(['Dashboard', 'Lead', 'Bulk Upload']);
        setBreadcrumbLink(['/dashboard', '/dashboard']);
    }, [setBreadcrumb, setBreadcrumbLink]);

    const handelBulkUpload = async (event) => {
        event.preventDefault();
        try {
            setProceeding(true);
            const formData = new FormData();
            formData.append('file', selectedFile);
            const token = getToken('accessToken');

            const config = {
                method: 'post',
                url: 'https://api.webhizzy.in/api/lead/bulk-upload',
                maxBodyLength: Infinity,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            };
            const response = await axios.request(config);
            if (!response.error) {
                openModal('success', response.data.message);
                setProceeding(false);
                navigate('/dashboard');
            } else {
                openModal('failure', response.data.message);
                setProceeding(false);
            }
        } catch (error) {
            if (error.message === 'Network Error') {
                openModal('failure', "No Internet Connection.");
            } else {
                openModal('failure', error.message);
            }
        }
    };

    return (
        <>
            <div className="p-4 bg-white sm:p-6 dark:bg-gray-800">

                <div className='grid grid-cols-2'>
                    <div className='col align-bottom'>
                        <h3 className="text-xl pt-3 font-bold text-gray-900 dark:text-white">Lead Bulk Upload</h3>
                    </div>
                    <div className="col text-sm mt-3 mb-2 w-full text-right font-normal text-gray-500 dark:text-gray-400">
                        <a href={sampleFile} download="sample_leads.xlsx">
                            <button type="button" className="inline py-2 px-5 me-2 mb-2 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            <svg className='inline w-4 h-4 me-2'
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 26 26"
                            xmlSpace="preserve"
                            fill="#1d6f42"
                        > <g id="SVGRepo_bgCarrier" strokeWidth={0} /> <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round" /> <g id="SVGRepo_iconCarrier"> <g> <path d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162 C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8 h-4V6h4V8z"/><path d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678 H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037 c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107 L9.488,18.08L9.488,18.08z"/></g></g></svg>
                                Sample File
                            </button>
                        </a>
                    </div>
                </div>

                <form method='post' onSubmit={(e) => { handelBulkUpload(e) }}>
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="small_size">Select Lead File</label>
                            <input accept=".xlsx" onChange={handleFileChange} className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="small_size" type="file" required />

                        </div>
                        <div className="col-span-6 sm:col-full">
                            <button className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800" type="submit">
                                {proceeding ? (<>
                                    <div className='inline mr-3' role="status">
                                        <svg aria-hidden="true" className="w-5 h-5 inline text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </>) : (<></>)}Upload Lead</button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    );
};

export default Post;